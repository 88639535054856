.geosuggest__input {
  @apply block w-full !bg-gray-100 text-gray-700 border !border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none;
}

.geosuggest__suggests {
  @apply absolute top-full w-full mt-1 shadow z-10 bg-gray-50 rounded-sm border border-gray-200;
}
.geosuggest__suggests--hidden {
  @apply max-h-0 overflow-hidden border-0;
}

.geosuggest__item {
  @apply px-3 py-1 border-b cursor-pointer text-gray-900 hover:bg-gray-200;
}
