a {
  cursor: pointer;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.skeleton-animation {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    @apply bg-gray-300;
  }
  100% {
    @apply bg-gray-400;
  }
}

/* Tractor Select */
.tractor-select__control {
  @apply !bg-gray-100 !text-gray-700 !border !border-gray-200 !rounded !leading-tight !pl-2;
}

.tractor-select__control--is-focused {
  @apply !shadow-none;
}

.tractor-select__option {
  @apply !border-b !bg-gray-50 !cursor-pointer !px-3 !py-1;
}

.tractor-select__option:hover {
  @apply !bg-gray-200;
}

.tractor-select__option--is-focused {
  background-color: pink;
  color: red;
}

.tractor-select__option--is-selected {
  @apply !text-gray-900 !bg-gray-100 !font-bold;
}

.tractor-select__dropdown-indicator {
  color: red;
}

.tractor-select__placeholder {
  @apply !text-gray-400;
}

.tractor-select__single-value {
  @apply !text-gray-700;
}

/* .tractor-select__menu {
  @apply !m-0 !p-0;
} */

.tractor-select__menu-list {
  @apply !m-0 !p-0;
}

/* .tractor-select__menu {
  margin-top: 0.125rem;
  border-radius: 0;
  padding: 0;
}

.tractor-select__menu-list {
  padding: 0;
} */
