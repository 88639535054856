.body h2 {
	font-size: 2rem;
	font-weight: 600;
	margin-bottom: 1rem;
}

.body h3 {
	font-size: 1.75rem;
	font-weight: 600;
	margin-bottom: 1rem;
}

.body h4 {
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 1rem;
}

.body h5 {
	font-size: 1.25rem;
	font-weight: 600;
	margin-bottom: 1rem;
}

.body h6 {
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 1rem;
}

.body p {
	margin-bottom: 1rem;
	font-size: 1.125rem;
}

.body a {
	@apply text-orange-600 underline;
}
